import React from "react";
export default ({ data }) => {  
  return (
    <React.Fragment>
      <a
        href={data.link}
        target="_blank"
        className="shadow-box news w-inline-block"        
      >
        <img src={data.image.url} width="170" className="news-thumb" alt={data.image.alt}/>
        <div className="news-title">
          <div>
            <h5 className="h5">{data.title}</h5>
            <div className="tiny-copy">{data.publishedDate}</div>
          </div>
          <div className="tiny-copy">{data.hostName}</div>
        </div>
      </a>
    </React.Fragment>
  );
};
