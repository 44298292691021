import React from "react";
import { graphql } from 'gatsby'
import PressDetail from "../components/press/press";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { resetWebflow } from "../utils/common";
import Notification from "../components/header/notification";
import { Pagination } from "../utils/pagination";
import ReactPaginate from 'react-paginate';
import Layout from "../layouts";

export class PressPage extends React.Component {
  constructor(props) {
    super(props);
    let {
      data: {
        datoCmsPress: { mainSection: items }
      }
    } = this.props;
    let paging = new Pagination(items, 14);

    this.state = {
      paging,
      data: paging.pageChanged()
    };
  }

  handlePageClick = data => {
    let page = data.selected + 1;
    this.setState({
      data: this.state.paging.pageChanged(page)
    });
  };

  render() {
    let {
      data: {
        datoCmsPress: {
          title,
          subtitle,
          mainSection,
          seoMetaTags,
          notificationPage
        },
        allDatoCmsNotification: { edges: notifications }
      }
    } = this.props;
    resetWebflow("5aabd62c51e22edab5346d1d");
    return (
      <Layout>
        <HelmetDatoCms seo={seoMetaTags} />
        <Notification
          notifications={notifications}
          linkedPage={notificationPage}
        />
        <header className="main-header about reducedbottom">
          <div className="container grid">
            <h1 className="h-nofixedwidth">{title}</h1>
            <div className="sm-spacing" />
            <div className="p center-align">{subtitle}</div>
            <div className="md-spacing" />
            {this.state.data.map(block => {
              return <PressDetail data={block} />;
            })}
          </div>
          <div className="md-spacing"></div>
          {this.state.paging.isShowPagination && (
            <ReactPaginate
              previousLabel={""}
              nextLabel={""}
              pageClassName={"page-number"}
              breakLabel={"..."}
              breakClassName={"page-number"}
              pageCount={this.state.paging.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          )}
        </header>
      </Layout>
    );
  }
}

export default PressPage;

export const query = graphql`
  query PressQuery {
    datoCmsPress {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
      notificationPage {
        id
        page
      }
      mainSection {
        ... on DatoCmsPressDetail {
          id
          title
          image {
            alt
            url
          }
          link
          hostName
          publishedDate(formatString: "MMM DD, YYYY")
        }
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
